.marketplace-body-activity-table-body-row {
  display: flex;
  justify-content: space-around;
}

.marketplace-body-activity-table-body-row > td {
  padding: 1rem 1.5rem;
}

.marketplace-body-activity-table-body-row-event {
  min-width: 3rem;
  text-transform: capitalize;
}

.marketplace-body-activity-table-body-row-event > svg {
  height: 1rem;
  width: 1rem;
  margin-right: 0.35rem;
}
