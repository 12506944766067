.list-token-modal-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.list-token-modal-content-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.list-token-modal-content-form > input {
  background-color: rgba(175, 104, 255, 0.08);
  border-color: #af68ff;
  border-radius: 5px;
  color: #fff;
  font-family: "Playmegamesreguler 200ee";
  padding: 0.5rem;
  margin-left: 1rem;
  font-size: 1.125rem;
  width: 100%;
}

.list-token-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.list-token-modal-content-form-input.switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.85rem;
}
.list-token-modal-content-form-input.private > input {
  height: 22px;
}
