.marketplace-body-card {
  width: 100%;
  max-width: 255px;
  border: 2px solid #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  cursor: pointer;
}

.marketplace-body-card-image-wrapper {
  width: 100%;
  border-radius: 6px 6px 0 0;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.marketplace-body-card-image-wrapper-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px #fff;
  border-radius: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.marketplace-body-card-content {
  background-color: #18091f;
  border-radius: 0 0 8px 8px;
  padding: 0.75rem 1rem;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-bottom-small {
  margin-bottom: 0.75rem;
}

.margin-xxsmall {
  margin: 0.5rem;
}

.text-size-medium {
  font-size: 1.3rem;
}

.marketplace-body-card-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marketplace-body-card-button-group-button {
  background-image: url("../../../../../assets/settings-dark.png");
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 175px 50px;
  border: 2px #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0.9rem;
  font-family: "Playmegamesreguler 200ee";
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 6;
}

.islight {
  background-image: url("../../../../../assets/settings-darkdouble.png");
  background-size: 125px;
  padding: 1rem 2rem;
}

.marketplace-body-card-highest-offer {
  display: flex;
  justify-content: space-between;
}
