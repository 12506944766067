.layout-grid-items-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-template-areas: ".";
  grid-auto-rows: minmax(200px, 1fr);
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-content: stretch;
  align-items: start;
  justify-items: start;
  display: grid;
  overflow: visible;
}

.no-items-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 16rem;
}
