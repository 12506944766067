.site-game-leaderboard-user {
  display: flex;
  border-radius: 8px;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.16);
  align-items: center;
  padding: 1.5rem;
  flex-direction: column;
  row-gap: 1rem;
}

.login-component-text-holder {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.login-component-text-holder > span:last-child {
  text-decoration: underline;
  cursor: pointer;
}

.loggedin-component-text-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button-main {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  background-position: 50%;
  background-repeat: no-repeat;
  border: 2px #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0.9rem;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.islightsmall {
  background-image: url("../../../../assets/settings-darkdouble.png");
  background-size: 100px;
  padding-left: 2rem;
  padding-right: 2rem;
}
