.filters1_filters-wrapper {
  max-width: 18rem;
  grid-area: span 1 / span 1 / span 1 / span 1;
  font-size: 1.125rem;
  border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.filters-header-container {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding: 2rem 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.filters-filter-container {
  padding-top: 0;
  width: 288px;
  font-size: 1.125rem;
}

.filters-header-container-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #935ad3;
  font-size: 1.125rem;
  cursor: pointer;
}

.filters-filter-container-type {
  cursor: pointer;
  background-color: #18091f;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.filters-filter-container-type-arrow {
  background-color: rgba(0, 0, 0, 0);
  color: #fff;
  border: none;
  height: 24px;
  cursor: pointer;
}

.filters-filter-container-type-arrow > svg {
  transition: all 0.3s;
}

.rot-180-active > svg {
  transform: rotate(180deg);
}

.filters-filter-container-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
  transition: all 1s;
}

.filters-filter-container-value-labels {
  display: flex;
  justify-content: space-between;
}

.filters-price-input {
  display: block;
  width: 100%;
  padding-left: 0.75rem;
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
  height: auto;
  background-color: #18091f;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.6;
  transition: all 0.2s;
}

.filters-price-input:hover {
  border-color: rgba(255, 255, 255, 0.6);
}
