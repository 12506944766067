.padding-top {
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.padding-xhuge {
  padding: 7rem;
}

.header9_component {
  height: 30rem;
  min-height: auto;
  flex-direction: column;
  display: flex;
}

.header9_image-wrapper {
  flex: 1;
  position: relative;
}

.header9_background-image-wrapper {
  z-index: 1;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.hide {
  display: none !important;
}

.image-overlay-layer {
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.header9_background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.padding-global {
  padding-left: 2rem;
  padding-right: 2rem;
}

.align-left {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.container-large {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.padding-vertical {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}

.padding-large {
  padding: 3rem;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-medium {
  margin: 2rem;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.contact22_component {
  grid-column-gap: 0rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 100px 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.contact22_item {
  width: 100%;
  border-right: 1px rgba(255, 255, 255, 0.16);
  flex-direction: column;
  align-items: center;
  padding-right: 0;
}

.margin-xsmall {
  margin: 1rem;
}

h4 {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Fffforwa, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.5;
}

.text-color-purple {
  color: #935ad3;
  font-size: 1.125rem;
}

.heading-style-h4.text-color-purple {
  color: #af68ff;
  font-size: 2rem;
}
