.faq {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  overflow-y: scroll;
  max-height: 600px;
}

.faq-item {
  display: flex;
  flex-direction: column;
}

.faq-header {
  font-size: 18px;
}

.faq-content {
  font-size: 16px;
}

.faq-content > a {
  color: rgb(146, 90, 210);
}

.faq > span > a {
  color: rgb(146, 90, 210);
}
