.product-header-token-actions-form {
  margin: 0 0 15px;
}

.product-header-token-actions-button-row {
  display: flex;
  justify-content: flex-start;
}

.product-header-token-actions-button {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../../../assets/settings-dark.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 175px 50px;
  border: 2px #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  font-family: "Playmegamesreguler 200ee", sans-serif;
  text-decoration: none;
  display: flex;
  font-size: 1.1rem;
  font-weight: 400;
  min-height: 2rem;
  letter-spacing: 1px;
  text-shadow: 1px 2px #000;
  margin-right: 1rem;
  cursor: pointer;
  width: max-content;
}

.islight {
  background-image: url("../../../../assets/settings-darkdouble.png");
  background-size: 125px;
  padding: 1rem 2rem;
}

.product-header-token-actions-button.islightsmall {
  background-image: url("../../../../assets/settings-darkdouble.png");
  background-size: 100px;
  padding: 0.75rem 2rem;
}
