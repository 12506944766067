.mobile-overlay {
  height: 3574px;
  display: block;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(0px) translateX(0px);
}

.mobile-overlay-menu {
  transform: translateY(0px) translateX(0px);
  transition: transform 400ms ease 0s;
}

@media screen and (max-width: 991px) {
  .mobile-overlay-menu {
    height: 100vh;
    -webkit-text-fill-color: inherit;
    background-color: rgba(0, 0, 0, 0);
    background-clip: border-box;
    justify-content: center;
    margin-right: 0;
    padding-left: 0%;
    padding-right: 0%;
    display: block;
    position: fixed;
    bottom: -47%;
    left: 0%;
    right: 0%;
    overflow: hidden;
    top: 0;
  }

  .mobile-overlay-link-wrapper {
    width: 100%;
    height: 100vh;
    object-fit: fill;
    background-color: #130618;
    border: 2px #925ad2;
    border-radius: 0;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: visible;
    box-shadow: 0 0 20px -8px #925ad2;
  }

  .mobile-overlay-link {
    text-transform: uppercase;
    text-decoration: none;
    height: 2.8rem;
    letter-spacing: 1px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    padding-top: 0.45rem;
    padding-bottom: 0.25rem;
    font-size: 1.125rem;
    transition: all 0.3s;
    display: flex;
    box-shadow: none;
    color: #fff;
    text-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    margin: 2rem 1.05rem 0;
  }

  .mobile-overlay-social-list {
    white-space: normal;
    grid-template-rows: auto;
    grid-template-columns: max-content;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    justify-items: start;
    display: grid;
    margin-top: 2rem;
    margin-left: 0;
    position: relative;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    align-items: center;
  }

  .mobile-overlay-social-link {
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    display: flex;
  }

  .mobile-overlay-button-wrapper {
    grid-row-gap: 1rem;
    flex-direction: column;
    align-items: stretch;
    margin-top: 2rem;
    margin-left: 0;
    grid-column-gap: 1rem;
    justify-content: center;
  }

  .mobile-overlay-play-button {
    text-decoration: none;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../../../assets/settings-dark.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 175px 50px;
    border: 2px #fff;
    border-radius: 0;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 0.9rem;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    display: flex;
    width: 200px;
  }
}
