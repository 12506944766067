.site--components--modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.site--components--modal {
  position: fixed;
  background-color: #130718;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 500px;
  border: 1px solid rgba(0,0,0,0.16);
  box-shadow: 0px 0px 10px #00000066;
  margin-bottom: 60px;
  overflow: auto;
  z-index: 23;
}

.site--components--modal.small {
  width: 350px;
}
