.site--game {
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
}

.site--game-content-holder {
  padding-left: 2%;
  padding-right: 2%;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 85rem;
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.site--game-header-container {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 3rem;
  width: 100%;
  max-width: 48rem;
}

.site--game-header-container > h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: none !important;
}

.site--game-player-content-holder {
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 25%;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.site--game-player-wrapper {
  display: flex;
  width: 100%;
  border: 3px solid #fff;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.site--game-leaderboard-wrapper {
  width: 100%;
  max-width: 85rem;
  margin-right: auto !important;
  margin-left: auto !important;
}

.site--game-leaderboard-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 6px;
  padding: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
  border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.site--game-leaderboard-header-primary {
  font-size: 1.125rem;
}

.site--game-leaderboard-header-secondary {
  font-size: 1.2rem;
  cursor: pointer;
  color: #fff;
}

.site-game-leaderboard-user {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 1rem;
}

.site--game-leaderboard-table-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  display: flex;
  flex-direction: column;
}

.site--game-leaderboard-table-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}

.users-score {
  background-color: #18091f;
}

.site--game-leaderboard-table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.site--game-leaderboard-table-pagination-pages {
  display: flex;
  width: 80px;
  justify-content: space-around;
}

.pagination-num {
  cursor: pointer;
}

.current-page {
  text-decoration: underline;
}

.site--game-leaderboard-table-pagination-button {
  min-height: 2.5rem;
  min-width: 6.5rem;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  color: #fff;
  cursor: pointer;
  column-gap: 4px;
}

.site--game-leaderboard-table-pagination-button:disabled {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.2);
}

.prev {
  justify-content: flex-start;
  margin-right: auto;
}

.prev > svg {
  transform: rotate(90deg);
  height: 16px;
  width: 16px;
}

.next {
  justify-content: flex-end;
  margin-left: auto;
}

.next > svg {
  transform: rotate(270deg);
  height: 16px;
  width: 16px;
}

@media screen and (max-width: 1280px) {
  .site--game-player-content-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}

@media screen and (max-width: 910px) {
  .site--game-player-wrapper {
    border: none;
  }
}
