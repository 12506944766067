.site--user {
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
}

.padding-top {
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important;
}

.padding-xhuge {
  padding: 10rem;
}

.container-large {
  width: 100%;
  max-width: 100%;
  margin-right: auto !important;
  margin-left: auto !important;
}

.user-layout {
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.user-profile-wrapper {
  max-width: 18rem;
  grid-area: span 1 / span 1 / span 1 / span 1;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  padding: 1rem;
}

.text-size-small {
  color: #fff;
  font-size: 1rem;
}

.padding-xsmall {
  padding: 1rem;
}
