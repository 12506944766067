.site--claim-mermaid-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site--claim-mermaid-card-img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
}

.site--claim-mermaid-card-image {
  border-radius: 8px;
}
