.site--claim {
  background-image: none;
  background-position: 0 0;
  background-size: auto;
  width: 100%;
  max-width: none;
  background-color: #0f0f0f;
}

.site--claim-section {
  position: relative;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  max-width: 48rem;
  margin-right: auto !important;
  margin-left: auto !important;
}

.site--claim-section-claim {
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
}

.site--claim-section-claim-header {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 1.5rem;
}

.site--claim-section-claim-header-heading {
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Fffforwa, sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.8;
}

.site--claim-section-claim-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  min-height: auto;
  background-color: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.14);
  border-radius: 12px;
  margin-bottom: 2rem;
  padding: 3rem;
}

.site--claim-section-claim-wrapper-text {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 2rem;
}

.site--claim-section-claim-wrapper-button {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
  justify-content: center;
}

.site--claim-section-claim-wrapper-claim {
  width: 170px;
  background-size: 160px;
  padding: 1rem 0;
  background-image: url("../../assets/settings-darkdouble.png");
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  border: 2px #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  height: 55px;
  cursor: pointer;
}

.light {
  color: rgba(255, 255, 255, 0.14);
}

.claimed-nav-link {
  text-decoration: none;
  color: rgb(146, 90, 210);
}
