.page-wrapper {
  color: #fff;
  font-family: "Playmegamesreguler 200ee", sans-serif;
}

.background-color-black {
  color: #fff;
  background-color: #130718;
}

.site--landing {
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
}

.container-large {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.filters1_layout {
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
