.padding-row {
  padding: 1rem 1.5rem;
}

.text-align-left {
  text-align: start;
}

.offers-table-row-wrapper {
  display: flex;
  padding: 1rem 1.5rem;
}
