.claim-profile-modal-content {
  max-height: 600px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1rem;
}

.claim-profile-image-select-button {
  border-radius: 100px;
  border: none;
  background: none;
  cursor: pointer;
}

.claim-profile-image-select-button > img {
  border-radius: 100px;
}

.wide {
  width: 200px;
}
