.unity-player-overlay-container {
  width: 947px;
  height: 702px;
  position: relative;
}

.site--unity-player {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.site--unity-player-button {
  margin-top: 2rem;
  display: flex;
  height: 2rem;
  width: fit-content;
  border: none;
  background: transparent;
  align-items: center;
  justify-content: flex-end;
  column-gap: 0.6rem;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
}

.site--unity-player-button > svg {
  height: 1.2rem;
  width: 1.2rem;
}

.player-buttons {
  display: flex;
  column-gap: 1rem;
}

@media screen and (max-width: 910px) {
  .unity-player-overlay-container {
    width: 300px;
    height: 200px;
  }
}
