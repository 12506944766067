.site--faq {
  max-width: 85rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8rem;
  padding-bottom: 2rem;
}

.page-faq {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.page-faq-item {
  display: flex;
  flex-direction: column;
}

.page-faq-header {
  font-size: 18px;
}

.page-faq-content {
  font-size: 16px;
}

.page-faq-content > a {
  color: rgb(146, 90, 210);
}

.page-faq > span > a {
  color: rgb(146, 90, 210);
}
