.mobile-nav {
  float: right;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 18px;
  font-size: 24px;
  display: none;
  position: relative;
}

@media screen and (max-width: 991px) {
  .mobile-nav {
    display: block;
    padding: 0;
  }

  .menu-icon {
    z-index: 20;
    width: 48px;
    height: 48px;
    background-image: url("../../../../assets/menu-icon.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -0.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
    position: relative;
  }

  .menu-icon-top {
    width: 24px;
    height: 2px;
    background-color: #fff;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon-middle {
    width: 24px;
    height: 2px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .menu-icon-bottom {
    width: 24px;
    height: 2px;
    background-color: #fff;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon-middle-inner {
    width: 4px;
    height: 0;
    padding-bottom: 0;
    padding-right: 0;
  }
}
