.token-header-offers-wrapper {
  object-fit: fill;
  background-color: #18091f;
  border-radius: 0;
  padding-bottom: 0;
  display: block;
  position: relative;
  overflow: auto;
}

.offers-table-row {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.token-header-offers-table {
  display: block;
  border: 2px solid #fff;
  border-radius: 8px;
  overflow: auto;
}

.token-header-offers-table-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.token-header-offers-table-header-title {
  min-height: auto;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.token-header-offers-table-header-row {
  display: flex;
  column-gap: 2rem;
}

.offers-table-row-no-content {
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: center;
}

.offers-row-price {
  min-width: 6rem;
}

.offers-row-bidder {
  min-width: 10rem;
}

.offers-row-bidder.caps {
  text-transform: uppercase;
}

.offers-row-expiry {
  width: 8rem;
}

.token-offer-row-accept-wrapper {
  width: max-content;
}

.offers-table-row-wrapper {
  padding: 1rem 1.5rem;
  align-items: center;
}
.offers-table-row-button-wrapper {
  display: flex;
  justify-content: right;
}
