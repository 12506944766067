.page--404 {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
}

.page--404-block {
  max-width: 20rem;
  text-align: center;
  flex-direction: column;
  display: flex;
  margin: 0 0 15px;
}

.image-404 {
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}

.padding-xxsmall {
  padding: 0.5rem;
}

.go-home-button {
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 1px 1px #000;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 400;
}
