.site--shared--form-timer {
  display: flex;
  font-size: 40px;
  font-weight: 100;
  font-family: "HaasGrotDisp";
  width: 200px;
}

.site--shared--form-timer.center {
  justify-content: center;
}

@media (max-width: 850px) {
  .site--shared--form-timer {
    font-size: 30px;
    width: 133px;
    margin-top: 20px;
  }
}
