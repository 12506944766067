.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  max-width: 300px;
}

.terms-subtext {
  color: rgba(255, 255, 255, 0.55);
  font-size: 1.05rem;
  text-align: center;
}

.terms-subtext > a {
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
}

.starlist-subtext {
  color: inherit;
  font-size: inherit;
}

.xumm-subtext > a {
  color: #b378f6;
  text-decoration: underline;
}

.xumm-subtext {
  color: inherit;
  font-size: inherit;
}

.starlist-subtext > a {
  color: #b378f6;
  text-decoration: underline;
}
