.marketplace--modal-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.marketplace--modal-header-close {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  cursor: pointer;
  height: 24px;
}
