.list-token-modal-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  align-items: center;
}

.list-token-modal-content-form {
  display: flex;
  align-items: center;
  column-gap: 2rem;
  justify-content: space-between;
}
.list-token-modal-content-form-input {
  display: flex;
  flex-direction: column;
}

.list-token-modal-content-form-input > input {
  background-color: rgba(175, 104, 255, 0.08);
  border-color: #af68ff;
  border-radius: 5px;
  color: #fff;
  font-family: "Playmegamesreguler 200ee";
  padding: 0.5rem;
  margin-left: 0rem;
  font-size: 1.125rem;
}

.list-token-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.list-token-modal-content-form-input > input.expiry-error {
  border-color: red;
}
