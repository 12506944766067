.list-token-modal-content {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.list-token-modal-content-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.list-token-modal-content-form > input {
  background-color: rgba(175, 104, 255, 0.08);
  border-color: #af68ff;
  border-radius: 5px;
  color: #fff;
  font-family: "Playmegamesreguler 200ee";
  padding: 0.5rem;
  margin-left: 1rem;
  font-size: 1.125rem;
  width: 100%;
}

.cancel-token-modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.list-token-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.cancel-token-listing-select {
  min-width: 2rem;
}

.cancel-token-listing {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cancel-token-listing-select-item {
  display: flex;
  align-items: center;
}

.cancel-token-listing-price-expiry {
  display: flex;
  column-gap: 2rem;
}

.cancel-token-listing-expiry {
  min-width: 7rem;
}

.cancel-token-listing-price {
  min-width: 6rem;
}
