.site--naviation-w-nav {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
  transition: all 3s;
  max-width: 85rem;
  width: 100%;
}

.site--navigation {
  background-image: url("../../../assets/Navbar.png");
  width: 100%;
  height: 6.2rem;
  min-height: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto 6rem;
  background-attachment: scroll;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4rem;
  padding-right: 4rem;
  display: flex;
  max-width: 85rem;
}

.site--navigation-link-socials-button-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  cursor: pointer;
}

.site--navigation-logo {
  padding-left: 1rem;
  transition: all 0.3s;
}

.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}

.site--navigation-link-container {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  position: static;
  cursor: pointer;
}

.site--navigation-link {
  text-decoration: none;
  height: 2.2rem;
  color: #130618;
  letter-spacing: 1px;
  text-shadow: 1px 0 #fff;
  background-color: transparent;
  border-radius: 8px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0.55rem 0.25rem 0.25rem;
  padding: 0.5rem 1rem;
  padding-top: 0.45rem;
  padding-bottom: 0.25rem;
  font-size: 1.125rem;
  transition: all 0.3s;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.2),
    inset 0 1px 0 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.site--navigation-link:hover {
  color: #f3f3f3;
  text-shadow: 2px 1px rgba(255, 255, 255, 0);
  background-color: #616161;
  border: 1px #925ad2;
  border-radius: 8px;
  box-shadow: inset 0 1px 0 3px #424242;
}

.site--navigation-socials-container {
  display: flex;
  column-gap: 1rem;
}

.site--navigation-connect-wallet-btn {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../../assets/settings-dark.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 175px 50px;
  border: 2px #fff;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0.9rem;
  font-family: "Playmegamesreguler 200ee", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  width: 200px;
  height: 4rem;
}

.site--navigation-pending-tx-button {
  background-color: #18091f;
  border: 2px solid #935ad3;
  border-radius: 5px;
  cursor: pointer;
}

.site--navigation-socials-link > img {
  min-width: 30px;
}

.square {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}

@media screen and (max-width: 991px) {
  .site--naviation-w-nav {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0%;
    padding-right: 0%;
  }

  .site--navigation-connect-wallet-btn {
    width: 135px;
    background-size: 135px 50px;
    display: none;
  }

  .site--navigation {
    max-width: none;
  }

  .site--navigation-link {
    display: none;
  }

  .site--navigation-socials-container {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .site--navigation {
    min-height: 4rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
