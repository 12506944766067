.user-profile-img-wrapper {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  height: 250px;
  width: 250px;
  border-radius: 100%;
  background-color: #925ad2;
}

.user-profile-img-wrapper.none {
  background-color: #dbdbdb;
}

.user-profile-img {
  object-fit: cover;
  border-radius: 5%;
}

.user-profile-info-wrapper {
  margin-bottom: 0.75rem;
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.user-profile-info-heading {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.id-wrapper {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  background-color: #18091f;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem;
  transition: all 0.2s;
  display: flex;
  cursor: pointer;
  position: relative;
  display: inline-flex;
}

.id-wrapper > svg {
  height: 16px;
  width: 16px;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #18091f;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 3;
}

.user-feed {
  grid-area: span 1 / span 1 / span 1 / span 1;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
}

.user-feed-selection {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.user-feed-selection-tags {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  margin-left: 0;
  margin-right: 1rem;
  display: flex;
}

.selection-tag {
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0);
  border-bottom-style: none;
  border-bottom-width: 1px;
  align-items: center;
  padding: 2rem;
  font-size: 1.25rem;
  transition: all 0.2s;
  display: flex;
  border: none;
  cursor: pointer;
}

.is-underline {
  color: #fff;
  border-bottom-style: solid;
  border-bottom-color: #fff;
}

.user-feed-header {
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  padding: 1rem;
}

.user-tags-wrapper {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  margin-left: 0;
  margin-right: 1rem;
  display: flex;
}

.user-tag {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  background-color: #18091f;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem 0.75rem;
  display: flex;
}

.id-wrapper-centered {
  grid-column-gap: 0.25rem;
  grid-row-gap: 0.25rem;
  background-color: #18091f;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  align-items: center;
  padding: 0.5rem;
  transition: all 0.2s;
  display: flex;
  cursor: pointer;
  justify-content: center;
}
