.marketplace-body-wrapper {
  grid-area: span 1 / span 1 / span 1 / span 1;
  border-left: 1px solid rgba(255, 255, 255, 0.16);
}

.marketplace-body-tags-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  grid-column-gap: 2rem;
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.marketplace-body-tags-wrapper-button.is-underlined {
  border-bottom: 1px solid #fff;
}

.marketplace-body-tags-wrapper-button {
  border: 0px;
  background: none;
  color: #fff;
  font-size: 1.125rem;
  cursor: pointer;
  padding: 2.32rem;
}

.marketplace-body-filters-feed-header {
  grid-column-gap: 2rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  padding: 1rem;
}

.marketplace-body-filters-feed-header-tag {
  margin-left: 0;
  margin-right: 1rem;
  text-transform: capitalize;
  background-color: #18091f;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  width: max-content;
  display: flex;
  justify-content: space-between;
  width: max-content;
}

.marketplace-body-filters-feed-header-tag > button {
  height: 24px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
}

.marketplace-body-section-cards-container {
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 100%;
  padding: 1rem;
  max-height: 1000px;
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden;
}
