.property-card {
  grid-area: span 1 / span 1 / span 1 / span 1;
  background-color: rgba(175, 104, 255, 0.04);
  border: 2px solid rgba(175, 104, 255, 0.5);
  transition: all 0.275s;
  border-radius: 8px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  padding: 1rem;
}

.property-card:hover {
  background-color: rgba(175, 104, 255, 0.08);
  border-color: #af68ff;
}

.property-card-content {
  border-radius: 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0;
  display: flex;
}

.margin-small {
  margin-bottom: 1rem;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.text-size-tiny,
.text-size-tiny.text-style-muted {
  font-size: 0.875rem;
}
.text-style-muted {
  opacity: 0.6;
}

p {
  margin-bottom: 0;
  line-height: 1;
  margin-top: 0;
}
