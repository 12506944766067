.token-header-listing-price-container {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  background-color: #18091f;
  border: 2px solid #fff;
  border-radius: 8px;
  align-items: flex-end;
  display: flex;
  padding: 1rem;
}

.field-label {
  margin-bottom: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
}

.text-size-xxlarge {
  font-size: 2rem;
  line-height: 1;
}
