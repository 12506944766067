.transaction-modal-content {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.transaction-modal-data-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.transaction-modal-status > span:first-child {
  color: rgba(255, 255, 255, 0.16);
}

.transaction-modal-status > span:last-child {
  text-transform: capitalize;
}

.cancel-payload-button {
  padding: 0.75rem 1.5rem;
  color: #935ad3;
  font-size: 1.1rem;
  border-radius: 8px;
  border: 1px solid #935ad3;
  background-color: #18091f;
  cursor: pointer;
}

.transaction-modal-txn-link {
  font-size: inherit;
  color: #935ad3;
  text-decoration: none;
}
