.attributes-filter-container-type {
  cursor: pointer;
  background-color: #18091f;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
  text-transform: capitalize;
}

.attributes-filter-container-values {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  transition: all 1s;
}

.attribute-value-container {
  text-transform: capitalize;
  font-size: 1.125rem;
  display: flex;
  align-items: baseline;
  padding-left: 1rem;
  justify-content: space-between;
}

.attribute-button-name {
  display: flex;
  align-items: baseline;
}

.attribute-value-container-button {
  width: 1.125rem;
  height: 1.125rem;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  margin: auto 0.75rem auto -1.25rem;
  transition: all 0.2s;
}

.attribute-value-container-button > svg {
  width: 1.125rem;
  height: 1.125rem;
}
