.marketplace-body-filters-feed-header-queries {
  display: flex;
  justify-content: flex-start;
}

.marketplace-body-filters-feed-header-sort {
  background-color: #18091f;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  flex-direction: row;
  padding: 0.5rem 0.75rem;
  display: flex;
  color: #fff;
  font-family: "Playmegamesreguler 200ee";
  font-size: 1.25rem;
}

.marketplace-body-section-cards-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-template-areas: ".";
  grid-auto-rows: minmax(200px, 1fr);
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-content: stretch;
  align-items: start;
  justify-items: start;
  display: grid;
  overflow: visible;
}

.marketplace-body-section-cards-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
}
