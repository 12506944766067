body {
  color: #fff;
  font-family: "Playmegamesreguler 200ee";
  background-color: #130718;
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  height: 100%;
}

h1 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 4rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
}

h2 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 3rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 2.5rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
}

h4 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 400;
  text-transform: uppercase;
}

h5 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 1.25rem;
  line-height: 1.8;
  font-weight: 400;
  text-transform: uppercase;
}

h6 {
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-family: "Fffforwa";
  font-size: 1rem;
  line-height: 1.8;
  font-weight: 400;
  text-transform: uppercase;
}

p {
  margin-bottom: 0rem;
  font-size: 1.125rem;
}

a {
  border-top-color: hsla(0, 0%, 0%, 1);
  border-right-color: hsla(0, 0%, 0%, 1);
  border-left-color: hsla(0, 0%, 0%, 1);
  border-bottom-color: hsla(0, 0%, 0%, 1);
  font-size: 1.125rem;
  text-decoration: underline;
}

ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}

ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}

li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding-left: 0.5rem;
}

img {
  display: inline-block;
  max-width: 100%;
  border: 0;
  vertical-align: middle;
}

label {
  margin-bottom: 0.25rem;
  font-weight: 500;
}

button {
  font-family: "Playmegamesreguler 200ee";
}
thead,
tbody {
  display: block;
}

input {
  font-family: "Playmegamesreguler 200ee";
}

.checkbox-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 2px;
  margin: auto 0.75rem auto -1.25rem;
  transition: all 0.2s;
}

svg {
  flex-shrink: 0;
}

.navlink-no-decoration {
  text-decoration: none;
  color: #fff;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(19, 6, 24);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(146, 90, 210);
  border-radius: 20px;
}

@font-face {
  font-family: "Playmegamesreguler 200ee";
  src: local("Playmegamesreguler 200ee"),
    url("./fonts/PlaymegamesReguler-2OOee.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "Fffforwa";
  src: local("Fffforwa"), url("./fonts/FFFFORWA.TTF") format("truetype");
  font-weight: regular;
}
