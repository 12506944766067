.cancel-token-listing-select-button {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 2px;
  cursor: pointer;
}

.cancel-token-listing-select-button > svg {
  width: 1.125rem;
  height: 1.125rem;
}
