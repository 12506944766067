.token-events-table-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 100%;
  max-width: 85rem;
  padding-bottom: 1rem;
}

.token-events-table {
  display: block;
  object-fit: fill;
  background-color: #18091f;
  border-radius: 0;
  padding-bottom: 0;
  position: relative;
  overflow: auto;
  border: 2px solid #fff;
  border-radius: 8px;
  overflow: auto;
}

.token-events-table-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.token-events-table-header-row {
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: space-between;
  text-transform: capitalize;
  align-items: center;
}

.token-events-table-row-empty {
  display: flex;
  justify-content: center;
  padding: 1rem 1.5rem;
}

.token-events-type {
  min-width: 6rem;
}

.token-events-amount {
  min-width: 8rem;
}

.token-events-from {
  min-width: 8rem;
}

.token-events-to {
  min-width: 8rem;
}

.token-events-market {
  min-width: 6rem;
}

.token-events-time {
  min-width: 8rem;
}
