.section-product-header {
  position: relative;
}

.product-container {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 100%;
  max-width: 85rem;
}

.product-header-component {
  padding-top: 10rem;
  padding-bottom: 3rem;
}

.product-header-layout {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  grid-template-rows: auto;
  grid-template-columns: 0.75fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.product-header-image-wrapper {
  display: block;
  position: relative;
}

.product-header-properties-wrapper {
  margin-top: 1.5rem;
}

.product-header-breadcrumb {
  width: 100%;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  display: flex;
}

.text-size-large {
  font-size: 1.375rem;
  line-height: 1.3;
}

.product-header-properties-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: stretch;
  display: grid;
}

.product-header-product-details {
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
}

.margin-bottom {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}

.margin-bottom.margin-small {
  margin-bottom: 1rem;
}

.product-header-product-details-breadcrumb-wrapper {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  align-items: center;
  display: flex;
}
.product-details-breadcrumb-link {
  color: #000;
  font-size: 1rem;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
}

.breadcrumb-right-icon {
  width: 1rem;
  height: 1rem;
  opacity: 0.6;
  font-size: 1.125rem;
}

.text-size-small.text-color-gray {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
}

.product-header-heading-wrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.margin-large {
  margin: 3rem;
}

.product-header-owner-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.product-header-links-wrapper {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  flex-direction: row;
  display: flex;
}

.product-header-links-link {
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.product-header-links-link > svg {
  width: 1rem;
  height: 1rem;
}

.product-header-details-wrapper {
  background-color: #18091f;
  border-bottom: 1px #000;
}

.product-header-details-padding {
  border: 2px solid #fff;
  border-radius: 8px;
  padding: 1rem;
}

.margin-custom2 {
  margin: 0 0 1.5rem;
}

.product-header-details-grid {
  border-bottom: 1px #000;
  overflow: hidden;
}

.product-header-details-grid-layout {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  justify-items: start;
  display: grid;
}

.margin-xsmall {
  margin-bottom: 0.75rem;
}

.details-content-wrapper {
  grid-column-gap: 0.35rem;
  grid-row-gap: 0.35rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text-size-regular {
  font-size: 1.125rem;
}

.text-color-gray {
  color: rgba(255, 255, 255, 0.6);
}

.caps {
  text-transform: uppercase;
}
