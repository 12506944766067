.filters-value-container {
  text-transform: capitalize;
  font-size: 1.125rem;
  display: flex;
  align-items: baseline;
  padding-left: 1rem;
  
}

.filters-value-container-button {
  width: 1.125rem;
  height: 1.125rem;
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  cursor: pointer;
  margin: auto 0.75rem auto -1.25rem;
  transition: all .2s;
}

.filters-value-container-button > svg {
  width: 1.125rem;
  height: 1.125rem;
}
