.marketplace-body-activity-container {
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 100%;
  padding: 2rem 2rem 4rem;
}

.marketplace-body-activity-table-container {
  background-color: #18091f;
  transition: max-height 0.5s ease;
  max-height: 550px;
}

.marketplace-body-activity-table {
  border: 2px solid #fff;
  border-radius: 8px;
  overflow: auto;
  width: 100%;
}

.marketplace-body-activity-table-head-row {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
}

.marketplace-body-activity-table-head-row > td {
  min-height: auto;
  padding: 1rem 1.5rem;
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.125rem;
  text-align: left;
}

.marketplace-body-activity-table-body {
  max-height: 500px;
  overflow-y: auto; /* Trigger vertical scroll    */
  overflow-x: hidden;
}

.marketplace-activity-event {
  min-width: 3rem;
}

.marketplace-activity-item {
  min-width: 10rem;
}

.marketplace-activity-price {
  min-width: 6rem;
}

.marketplace-activity-from {
  min-width: 6rem;
}

.marketplace-activity-to {
  min-width: 6rem;
}

.marketplace-activity-time {
  min-width: 7rem;
}

.marketplace-activity-item > a {
  text-decoration: none;
  color: #fff;
}

.marketplace-activity-item > a:hover {
  color: rgba(255, 255, 255, 0.6);
}
