.site--footer {
  z-index: 1;
  border: 1px #000;
  border-top-width: 10px;
  position: relative;
}

.site--footer-container {
  padding-left: 5%;
  padding-right: 5%;
  margin-right: auto !important;
  margin-left: auto !important;
  max-width: 80rem;
  padding-top: 7rem;
  padding-bottom: 21rem;
}

.site--footer-container-grid {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.site--footer-container-grid > img {
  justify-self: start;
}

.site--footer-container-grid-navlist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: start;
}
.site--footer-container-grid-navlist > span {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 1rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
}

.site--footer-container-grid-navlist > a {
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: #fff;
}

.site--footer-container-grid-socials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: start;
}

.site--footer-container-grid-socials > span {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-left: 0rem !important;
  margin: 1rem;
  font-size: 1.25rem;
  letter-spacing: 1px;
}

.site--footer-container-grid-socials > a {
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  color: #fff;
  max-width: 100%;
}

.site--footer-ragzy-info {
  color: #9e9e9e;
  text-align: center;
  margin-top: 2.6rem;
  font-size: 1rem;
}

.termslink {
  color: #b378f6;
  text-decoration: inherit;
  font-size: inherit;
  border-color: #000;
  background-color: rgba(0, 0, 0, 0);
}

.site--footer-zilla-info {
  color: #616161;
  text-align: center;
  margin-top: 0.6rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.zilla-link {
  color: #96e82c;
  letter-spacing: 1px;
  text-decoration: none;
}

.site--footer-background-image-wrapper {
  z-index: -1;
  background-image: url("../../../assets/mooncore-shine.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
